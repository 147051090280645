import moment from "moment";
import { globalConfig } from "../services/api";
import { pdfLogo } from "../assets/images/index";

  export const setPrice = (price) => {
    if (price == null) {
      return "";
    }
    return globalConfig.defaultCurrency + (typeof price === "number" ? setNumberPrecision(price) : price);
  };
  
  export const setNumberPrecision = (val) => {
    return val?.toFixed(2);
  };

  export const formattedDate = (timestamp) => `${new Date(timestamp).toLocaleString('en-US', { 
      day: 'numeric', 
      month: 'short', 
      year: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      hour12: true 
  })}`;

  export const displayBase64 = (image)=>"data:image/png;base64,"+image

 export const isBase64Image = (image) => {
    if(image?.startsWith('iVBORw0KGgoAAAANSUhEUg') || image?.startsWith('/9j/4AAQSkZJRgA')){
      return true
    }
    return false
  }

export const getImageSrc = (image, local)=> image?.startsWith('iVBORw0KGgoAAAANSUhEUg') || image?.startsWith('/9j/4AAQSkZJRgA') ? "data:image/png;base64,"+image : local

export const randomIdGenerator = (randomIdLength = 30) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';

  for (let i = 0; i < randomIdLength; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return id.match(/.{1,6}/g).join('-');
}

export const showDate = (date) => {
  if (!moment(date).isValid()) {
    return "-";
  }
  return moment(date).format("DD MMM YYYY");
};

export const showDateWithTime = (date) => {
  return moment(date).format("DD MMM YYYY h:mm a")
}
export const getPlanDescription = (description) => {
  if (!description) return;
  return description.split(" ")[0];
};

export const getPlanBundleTitle = (duration,description, labelsIot) => {
  if (!duration) return;

  if (duration === 1) {
    return `${labelsIot?.LBLI0182 || "Monthly Bundle"}`;
  }else if (duration === 3) {
    return `${labelsIot?.LBLI0183 || "Quarterly Bundle"}`;
  }else if (duration === 12) {
    return `${labelsIot?.LBLI0184 || "Annual Bundle"}`;
  }else if (duration === 36) {
    return `${labelsIot?.LBLI0185 || "3 Years Bundle"}`;
  }else {
    return description
  }

};


export const getPlanBundleDescription = (duration,description, labelsIot) => {
  if (!duration) return;

  if (duration === 1) {
    return `${labelsIot?.LBLI0173 }`;
  }else if (duration === 3) {
    return `${labelsIot?.LBLI0174 }`;
  }else if (duration === 12) {
    return `${labelsIot?.LBLI0175 }`;
  }else if (duration === 36) {
    return `3 ${labelsIot?.LBLI0176}`;
  }else {
    return getPlanDescription(description)
  }

};

export const getPlanText = (duration,description, labelsIot) => {
  if (!duration) return;

  if (duration === 1) {
    return `${labelsIot?.LBLI0165 }`;
  }else if (duration === 3) {
    return `${labelsIot?.LBLI0186 || "quarter" }`;
  }else if (duration === 12) {
    return `${labelsIot?.LBLI0114 }`;
  }else if (duration === 36) {
    return `3 ${labelsIot?.LBLI0176}`;
  }else {
    return getPlanDescription(description)
  }

};


export const getPaymentDescription = (description) => {
  if (!description) return;

  const firstWord = description.split(" ")[0];

  if (firstWord === "36") {
    return "3 years";
  } 
  
  if (firstWord.includes("ly")) {
    return firstWord.replace("ly", "");
  }

  return firstWord;
};

export const bundlePopularTag = {
  1: 'Popular',
  2: 'Value buy',
}

export const deviceName = [
  { name: 'Car',code:0 },
  { name: 'Bike',code:1 },
  { name: 'Jeep',code:2 },
  { name: 'Drone',code:3 }
];

const formatDecimals = (num) => {
  const calcDec = Math.pow(10, 1);
  return Math.trunc(num * calcDec) / calcDec;
}

const checkFloatingValue = (formattedData) => {
  return Number.isInteger(formattedData) ? formattedData : formatDecimals(formattedData)
}

export const checkDataSize = (dataSizeInBytes) => {
  const bytesInOneMB = 1000 * 1000; // 1 MB = 1024 KB, 1 KB = 1024 bytes
  const bytesInOneGB = bytesInOneMB * 1000; // 1 GB = 1024 MB
  if (dataSizeInBytes >= bytesInOneGB) {
    // Data is in GB
    const dataSizeInGB = dataSizeInBytes / bytesInOneGB;
    return checkFloatingValue(dataSizeInGB) + " GB";
  } else if (dataSizeInBytes >= bytesInOneMB) {
    // Data is in MB
    const dataSizeInMB = dataSizeInBytes / bytesInOneMB;
    return checkFloatingValue(dataSizeInMB) + " MB";
  } else if(dataSizeInBytes >= 1000) {
    // Data is in bytes
    const dataSizeInMB = dataSizeInBytes / 1000;
    return checkFloatingValue(dataSizeInMB) + " KB";
  } else {
    return 0 + " KB"
  }
};

export const capitalize = (str) => {
  if (!str) return "";
  const [first, ...rest] = str;
  return first.toUpperCase() + rest.join("").toLowerCase();
};


export const handleDownloadReceipt = (rowData) => {
  let orderId = rowData?.orderID || rowData?.orderId;
  const year = new Date().getFullYear()
  // Create the HTML content
  const content = `
    <div style="font-family: 'Inter', sans-serif; letter-spacing: 0px; display:flex;flex-direction:column;justify-content: space-between;height:100%;">
      <div style="padding: 20px 65px;">
        <table width="100%" style="padding:10px 0px;border-collapse: collapse; margin-top:20px;">
            <tbody>
                <tr style="display: flex;align-items:center;gap: 15px;">
                    <td style="text-align:left;">
                        <h1 style="color: #170134;font-size: 24px; font-family: 'Inter', sans-serif; font-weight: 700;line-height: 200%;letter-spacing: -1.2px;margin:0px;">Payment Receipt</h1>
                    </td>
                    <td>
                        <div style="height: 1px; width: 465px; background: #170134;margin-top:15px;"></div>
                    </td>
                </tr>
            </tbody>
        </table>
        <p style="margin-bottom:5px; margin-top:9px; letter-spacing: -0.6px;font-size: 13px;">Dear <strong>${rowData?.firstName},</strong> </p>
        <p style="letter-spacing: -0.6px;line-height: 150%;margin-top:5px;font-size: 13px;">Thank you for your payment. We're happy to confirm that your transaction has been successfully <br/> processed. A confirmation for the payment has been shared to your email ID <br/><strong>${rowData?.email}</strong>. </p>
        <p style="letter-spacing: -0.6px;font-size: 13px;margin-top:16px">Below are the details of your payment:</p>

        <table style="width: 100%; border: 1px solid #E7E7E7; font-family: 'Inter', sans-serif; border-collapse: collapse; margin-top: 20px;">
            <tr>
              <th colspan="2" align="left" style="background: #160131;padding:15px;border: 1px solid #160131;color:#fff;font-family: 'Inter', sans-serif;font-size: 13px;">Order details</th>
            </tr>
            <tr>
              <td style="padding:10px; font-size: 12px;border-bottom: 1px solid #E7E7E7;border-right: 1px solid #E7E7E7;font-family: 'Inter', sans-serif;">Associated Email id</td>
            <td style="padding:10px; font-size: 12px;border-bottom: 1px solid #E7E7E7;font-family: 'Inter', sans-serif;">${rowData.email}</td>
            </tr>
            <tr>
              <td style="padding:10px; font-size: 12px;border-bottom: 1px solid #E7E7E7;border-right: 1px solid #E7E7E7;font-family: 'Inter', sans-serif;">ICCID</td>
              <td style="padding:10px; font-size: 12px;border-bottom: 1px solid #E7E7E7;font-family: 'Inter', sans-serif;">${rowData.iccid}</td>
            </tr>
            <tr>
              <td style="padding:10px; border-bottom: 1px solid #E7E7E7;border-right: 1px solid #E7E7E7;font-size: 12px;font-family: 'Inter', sans-serif;">Order Value</td>
              <td style="padding:10px; border-bottom: 1px solid #E7E7E7;font-size: 12px;font-family: 'Inter', sans-serif;">${setPrice(rowData.totalOrderAmount)}</td>
            </tr>
            <tr>
              <td style="padding:10px; border-bottom: 1px solid #E7E7E7;border-right: 1px solid #E7E7E7;font-size: 12px;font-family: 'Inter', sans-serif;">Payment Method</td>
              <td style="padding:10px; border-bottom: 1px solid #E7E7E7;font-size: 12px;font-family: 'Inter', sans-serif;">${rowData.paymentGateway}</td>
            </tr>
            <tr>
              <td style="padding:10px; border-bottom: 1px solid #E7E7E7;border-right: 1px solid #E7E7E7;font-size: 12px;font-family: 'Inter', sans-serif;">Date</td>
              <td style="padding:10px; border-bottom: 1px solid #E7E7E7;font-size: 12px;font-family: 'Inter', sans-serif;">${showDate(rowData.createdTs)}</td>
            </tr>
            <tr>
              <td style="padding:10px; border-bottom: 1px solid #E7E7E7;border-right: 1px solid #E7E7E7;font-size: 12px;font-family: 'Inter', sans-serif;">Order ID</td>
              <td style="padding:10px; border-bottom: 1px solid #E7E7E7;font-size: 12px;font-family: 'Inter', sans-serif;"> ${orderId  ? orderId?.substring(0,8) : '-'}</td>
            </tr>
            <tr>
              <td style="padding:10px; border-bottom: 1px solid #E7E7E7;border-right: 1px solid #E7E7E7;font-size: 12px;font-family: 'Inter', sans-serif;">Transaction ID</td>
              <td style="padding:10px; border-bottom: 1px solid #E7E7E7;font-size: 12px;font-family: 'Inter', sans-serif;">${rowData.transactionId}</td>
            </tr>
            <tr>
              <td style="padding:10px; border-right: 1px solid #E7E7E7;font-size: 12px;font-family: 'Inter', sans-serif;">Status</td>
              <td style="padding:10px; font-size: 12px;font-family: 'Inter', sans-serif;">${capitalize(rowData.transactionStatus)}</td>
            </tr>
        </table>

        <p style="letter-spacing: -0.6px;line-height: 150%;margin-top:40px;font-family: 'Inter', sans-serif;font-size: 13px; ">If you have any questions or need assistance, please don't hesitate to contact our support team at <strong>support@data2go.mobi.</strong></p>

        <p style="letter-spacing: -0.6px;line-height: 150%;margin-top:75px;font-family: 'Inter', sans-serif; font-size: 13px;">We look forward to serving you!</p>
        <img src="${pdfLogo}" alt="data2go" style="width: 150px; margin-top:10px" />

        </div>
        <div class="footer" style="background: #170134; border-bottom: 8px solid #F84564; color: #fff; padding: 15px 15px; margin-top:209px;font-family: 'Inter', sans-serif; ">
            <table width="100%" style="border-collapse:collapse; font-family: 'Inter', sans-serif;">
                <tbody>
                    <tr>
                        <td>
                            <p style="font-size: 11px; text-align:center;margin:0px;font-family: 'Inter', sans-serif;">Copyright ${year} <a href="https://data2go.mobi" target="_blank" style="color: #fff;">data2go</a>. All rights reserved.</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  `;
  const element = document.createElement('div');
  element.innerHTML = content;
  return element; // Return this for pdf generation
};

export const validateTagValue = (text) => {
  if (text.length === 0) {
    return false;
  } else if (text.length > 0) {
    return text.length >= 3 && text.length <= 11;
  }
};