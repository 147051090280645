import React, { useEffect } from "react";
import Header from "../../../components/layout/header";
import { Formik, Form } from "formik";
import * as PR from "../../../prime-modules/index";
import { User, Password } from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { login } from "../../../store/auth.slice";
import { handleSignin } from "../../../services/api";
import { siginValidationSchema } from "../../../utils/schema";
import Input from "../../../components/form/Input";
import GoogleAndAppleLoginButtons from "../../../components/auth/GoogleAndAppleLoginButtons";
import { useTranslationContext } from "../../../context/TranslationContext";
import { useToast } from "../../../context/ToastContext";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../components/layout/footer";

const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {showToast} = useToast()
  const {labelsIot} = useTranslationContext()
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if(isAuthenticated){
      navigate("/dashboard")
    }
  }, [isAuthenticated, navigate])

  const { mutate, isLoading } = useMutation(handleSignin, {
    onSuccess: o => { dispatch(login(o)) },
    onError: o => showToast("error",o?.message)
  });

  const initialValues = {
    email: "",
    password: "",
  }

  const loginSubmitHandler = (values, { setSubmitting }) => {
    mutate(values);
    setSubmitting(false);
  }

  return (
      <section className="auth-wrapper">
        <Header />
        <div className="form-wrapper">
          <div className="grid grid-nogutter align-items-center w-12">
            <div className="col-12 md:col-6 left-block">
              <h1>{labelsIot?.LBLI0001} <br />{labelsIot?.LBLI0002} <br />{labelsIot?.LBLI0145} </h1>
            </div>
            <div className="col-12 md:col-6">
              <div className="form-block">
                <Formik
                  initialValues={initialValues}
                  validationSchema={siginValidationSchema}
                  onSubmit={loginSubmitHandler}>
                  <Form autoComplete="off">
                    <div className="grid">
                      <div className="col-12">
                        <div className="card flex flex-column relative">
                          <Input
                            label=""
                            name="email"
                            type="email"
                            placeholder={labelsIot?.LBLI0010}
                          />
                          <PR.Image src={User} alt="Mail" className="icon" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card flex flex-column relative gap-2">
                          <Input
                            label=""
                            name="password"
                            type="password"
                            feedback={false}
                            toggleMask
                            placeholder={labelsIot?.LBLI0009}
                          />
                          <PR.Image src={Password} alt="Mail" className="icon" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card flex flex-column">
                          <PR.Button disabled={isLoading} type="submit" label={labelsIot?.LBLI0004} className="login-button" />
                        </div>
                        <div className="card text-right mt-4">
                            <Link to={'/forgot-password'}>{labelsIot?.LBLI0054}</Link>
                        </div>
                      </div>
                      <div className="col-12">
                        <GoogleAndAppleLoginButtons screen="signin" />
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </section>
  );
};

export default Signin;