export const USER_EMAIL = 'user-email';
export const USER_SESSION_DATA = 'user-session-iot-data';
export const ICCID = 'user-iccid';
export const CURRENT_PAYMENT_PLAN = "CURRENT-PAYMENT-PLAN"
export const LANGUAGE_IOT = 'language';
export const languages = [
    { name: "English", code: "EN" },
    { name: "Danish", code: "DA" },
  ];
export const languageList = ['en','da']
