import React, { useCallback, useEffect } from "react";
import * as PR from "../../prime-modules/index";
import { faqDark, logoDark, logoIcon } from "../../assets/images";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { setLanguageStore } from "../../store/language.slice";
import { useDispatch, useSelector } from "react-redux";

import { googleLoginAPI } from "../../services/api";
import { useQuery } from "react-query";
import { login } from "../../store/auth.slice";
import { useTranslationContext } from "../../context/TranslationContext";
import { useLanguage } from "../../hooks/useLanguage";
import { languageList, languages } from "../../utils/localstore";

// This iccid is coming form sign up component. Useful to send the iccid in google login request
const Header = ({ iccid= "" }) => {
  const languageAssert = useSelector(state => state.language)
  const {labelsIot} = useTranslationContext()
  const { isAuthenticated } = useSelector((state) => state.auth);
  let currentPageSignUp = window.location.pathname === "/signup"
  const { i18n } = useTranslation();
  const i18Language = i18n.language.split('-')[0]
  const language =  languageList.includes(i18Language) ? i18Language : 'en'
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {changeLanguage} = useLanguage(languageAssert,language)
  const { search, pathname } = useLocation();
  const code = new URLSearchParams(search).get("code");

  const { refetch, isLoading } = useQuery({
    queryFn: () => {
      return googleLoginAPI(`?code=${code}&iccid=${iccid}`);
    },
    retry:1,
    onSuccess: (data) => {
      navigate('/'); dispatch(login(data))
    },
    onError: (o) => {
      // showToast("error",o?.message)
    },
    queryKey: ['googleLogin'],
    enabled: false
  });

  const googleLoginHandler = useCallback(async () => {
    refetch()
  }, [refetch])

  useEffect(() => {
    if (code) {
      navigate(pathname)
      googleLoginHandler()
    }
  }, [code, googleLoginHandler, pathname, navigate])

  useEffect(() => {
    if(!!languageAssert[language]){
        dispatch(setLanguageStore(languageAssert[language])) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, dispatch])


  const handleButtonClick = () => {
    navigate(currentPageSignUp ? '/' : "/signup");
  };
  const allowedFaqPaths = ['/', '/signup', '/verify-email','/forgot-password','/update-email','/privacy-policy','/terms-and-conditions'];
  const start = <Link to={'/'}>
      <PR.Image src={logoDark} alt="data2go IOT Logo" className="logo-img" />
      <PR.Image src={logoIcon} alt="data2go IOT Logo" className="logo-mobile" />
    </Link>
  const end = <div className="flex align-items-center gap-5 ">
         {allowedFaqPaths.includes(pathname) ? <Link to={`/faq`} className="flex mt-2 items-center justify-center" >
                <PR.Image
                  src={faqDark}
                  alt={"faq Dark"}
                />
                 <span className="ml-2 text-gray-900">{labelsIot?.LBLI0121}</span>
              </Link>:null}
              <PR.Dropdown
                  value={languages?.filter(e=> e.code.toLowerCase()===language)?.[0]}
                    onChange={changeLanguage}
                    options={languages}
                    optionLabel="name"
                    placeholder="English"
                    className="language-dropdown"
                    panelClassName={`language-panel ${isAuthenticated ? 'authenticated' : ''}`}
                />
   {!!isAuthenticated ? '' : <PR.Button label={currentPageSignUp ? labelsIot?.LBLI0004 : labelsIot?.LBLI0003 } className="login-button" onClick={handleButtonClick} />}
  </div>
  return (
    <>
      {isLoading && <div className="full-window-loading sign-in-loading">
        <p>
          <i className="pi pi-spin pi-spinner mr-2"></i>
            {labelsIot?.LBLI0139}
        </p>
      </div>}

      <header className="w-12 header-section">
        <PR.Menubar start={start} end={end} className="w-12 main-header" />
      </header>
    </>
  )
}

export default Header