import React, { createContext, useContext, useRef } from 'react';
import * as PR from "../prime-modules/index";
import { globalConfig } from '../services/api';
import { useTranslationContext } from './TranslationContext';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toast = useRef(null); 
  const {labelsIot, messageCatalog:catalogIds} = useTranslationContext()
  const toastKeys = {
      "success": "LBLI0060",
      "error": "LBLI0061",
      "info": "LBLI0062"
    }
  
  const showToast  = (severity, messageId) => {
    const id = toastKeys[severity];
    if (toast.current) {
        toast.current.clear()
        toast.current.show({
            life: severity === "error" ? globalConfig.errorToastDisplayTime :  globalConfig.toastDisplayTime,
            severity: severity,
            summary: labelsIot[id],
            detail: catalogIds[messageId] ? catalogIds[messageId] :  messageId,
        });
    }
  };
  const showMessage = (messageId) => catalogIds[messageId] ? catalogIds[messageId] || "Something went wrong. Please try again later!" :  messageId || "Something went wrong. Please try again later!";

  return (
    <ToastContext.Provider value={{showToast, showMessage}}>
      <PR.Toast ref={toast} position="bottom-center" baseZIndex={9999} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};