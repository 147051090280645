import React, { useEffect, useState } from "react";
import "./index.scss";
import { useTranslationContext } from "../../context/TranslationContext";
import * as PR from "../../prime-modules/index";
import useDebounce from "../../hooks/useDebounce";
import { useLocation, useNavigate } from "react-router-dom";

const FAQ = () => {
  const { labelsIot, faq } = useTranslationContext();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [categories, setCategories] = useState({});
  const [faqData, setFaqData] = useState({});
  const searchValue = useDebounce(searchKeyword, 1000);
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search).get('query');
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!faq) return;
    if (!searchValue.trim()) {
      getFAQData(faq); // Load default FAQ data when search is empty
    } else {
      handleSearch(faq, searchValue);
    }
  }, [faq, searchValue]);
  
  const getFAQData = (faqsNew) => {
    const categoriesData = faqsNew?.categories ? JSON.parse(faqsNew.categories) : {};
    
    const filteredCategories = Object.fromEntries(
      Object.entries(categoriesData).filter(([_, value]) => value.status !== 0)
    );
  
    const filteredFaqData = Object.fromEntries(
      Object.keys(filteredCategories)
        .filter((key) => key.startsWith("FAQNEW"))
        .map((key) => [key, faqsNew[key] ? JSON.parse(faqsNew[key]) : {}])
    );
  
    setCategories(filteredCategories);
    setFaqData(filteredFaqData);
      setLoading(false)
  };
  
  const handleSearch = (faqsNew, keyword) => {
    const categoriesData = faqsNew?.categories ? JSON.parse(faqsNew.categories) : {};
  
    const filteredCategories = Object.fromEntries(
      Object.entries(categoriesData).filter(([key, value]) => {
        if (value.status === 0) return false;
        const faqItems = faqsNew[key] ? JSON.parse(faqsNew[key]) : [];
        return faqItems.some((item) => item?.question.toLowerCase().includes(keyword.toLowerCase()) || item?.answer.toLowerCase().includes(keyword.toLowerCase()));
      })
    );
  
    const filteredFaqData = Object.fromEntries(
      Object.keys(filteredCategories)
        .filter((key) => key.startsWith("FAQNEW"))
        .map((key) => {
          const faqItems = faqsNew[key] ? JSON.parse(faqsNew[key]) : [];
          const filteredItems = faqItems.filter((item) =>
            item?.question.toLowerCase().includes(keyword.toLowerCase()) ||  item?.answer.toLowerCase().includes(keyword.toLowerCase())
          );
          return filteredItems.length ? [key, filteredItems] : null;
        })
        .filter(Boolean)
    );

    setCategories(filteredCategories);
    setFaqData(filteredFaqData);
  };
  const generateSlug = (text) =>
    text ? text.toLowerCase().replace(/[^\w\s]/g, "").replace(/\s+/g, "-") : "";
  const faqTabChangeHandler = (title) => {
     const fagSlug = generateSlug(title);
    const params = new URLSearchParams();
    if (fagSlug) params.set("query", fagSlug);
    if(location.pathname === '/faq'){
      navigate(`/faq?${params.toString()}`, { replace: true });
    }
    // else{
    //   navigate(`/dashboard?${params.toString()}`, { replace: true });
    // }
  }

  const splitCategories = () => {
    
    const allCategories = Object.entries(categories)
    const midpoint = Math.ceil(allCategories.length / 2);
    const leftCategoriesNew = allCategories.slice(0, midpoint);
    const rightCategories = allCategories.slice(midpoint);
    
    const renderTabs = (categoriesPart) =>
      categoriesPart.map(([id,obj],index) =>{
        let activeIndex = null
        if(query && location.pathname === '/faq'){
          activeIndex = faqData[id].findIndex(item=>generateSlug(item.question.toLowerCase()).includes(query.toLowerCase()))
        }
        return(
        <div key={index} className="faq-category">
          <h2 className="category-h2">{obj.name}</h2>
          <PR.Accordion activeIndex={activeIndex}>
            {faqData[id].map((item, i) =>(
              <PR.AccordionTab key={i}
              header={<div className="question" onClick={() => faqTabChangeHandler(item.question)}>{item.question}</div>}>
                <div dangerouslySetInnerHTML={{ __html: item?.answer }} />
              </PR.AccordionTab>
            ))}
          </PR.Accordion>
        </div>
      )});

    return {
      leftTabs: renderTabs(leftCategoriesNew),
      rightTabs: renderTabs(rightCategories),
    };
  };

  const { leftTabs, rightTabs } = splitCategories();

  return (
    <div className="add-device">
      <div className="content-header flex align-items-center justify-content-between">
        <h1>{labelsIot?.LBLI0137}</h1>
        <div className="search">
          <PR.Button icon="pi pi-search" className="search-button" />
          <PR.InputText
            onChange={(e) => setSearchKeyword(e.target.value)}
            placeholder={labelsIot?.LBLI0021}
          />
        </div>
      </div>
      {!loading && Object.keys(categories).length > 0 ? (
        <div className="settings-section faq-section">
          <div className="grid grid-nogutter w-12">
            <div className="col-12 md:col-6 left-block">{leftTabs}</div>
            <div className="col-12 md:col-6 right-block">{rightTabs}</div>
          </div>
        </div>
      ) : loading ?   <div className="empty-section faq-empty-section settings-section faq-section">
        <div className="grid grid-nogutter w-12">
            <div className="col-12 md:col-6 left-block">
              <div className="faq-category">
                <PR.Skeleton width="50%" height="1.5rem" className="mb-4" />
                <PR.Skeleton width="100%" height="4rem" className="mb-3" />
                <PR.Skeleton width="100%" height="4rem" className="mb-3" />
                <PR.Skeleton width="100%" height="4rem" className="mb-1" />
              </div>
            </div>
            <div className="col-12 md:col-6 right-block">
              <div className="faq-category">
                <PR.Skeleton width="50%" height="1.5rem" className="mb-4" />
                <PR.Skeleton width="100%" height="4rem" className="mb-3" />
                <PR.Skeleton width="100%" height="4rem" className="mb-3" />
                <PR.Skeleton width="100%" height="4rem" className="mb-1" />
              </div>
            </div>
        </div>
    </div>:(
        <div className="empty-section faq-empty-section">
          <p>{labelsIot?.LBLI0138}</p>
        </div>
      )}
    </div>
  );
};

export default FAQ;
