import React from 'react'
import Header from "../../components/layout/header";
import * as PR from "../../prime-modules/index";
import { useTranslationContext } from "../../context/TranslationContext";
import { CURRENT_PAYMENT_PLAN } from '../../utils/localstore';
import { getPlanBundleDescription, getPlanBundleTitle, setPrice } from '../../utils/reuse';
import Footer from '../../components/layout/footer';
import { Checkmark } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const PaymentProvider = ({children, isLoading}) => {
  const { i18n } = useTranslation();
  const language = i18n.language.split('-')[0];
  const {labelsIot} = useTranslationContext()
  const currentPaymentPlan = JSON.parse(localStorage.getItem(CURRENT_PAYMENT_PLAN))

  return ( 
        <section className="auth-wrapper">
          <Header />
          <div className="payment-section flex flex-column justify-content-center">
            <div className="grid grid-nogutter align-items-center w-12">
              <div className="col-12 md:col-3 mobile-full-width">
                <div className="plan-block">
                  <div className="bundle-box">
                    {!!currentPaymentPlan?.label ? <div className="popular-tag">
                      <i className="pi pi-star-fill"></i>
                      <span>{`${labelsIot?.LBLI0162} ${currentPaymentPlan?.label}`}</span>
                    </div>:null}
                    <h2>{getPlanBundleTitle(currentPaymentPlan?.duration,currentPaymentPlan?.description,labelsIot)}</h2>
                    <h3>
                      {setPrice(currentPaymentPlan?.price)}
                    </h3>
                    <p className="mb-2">
                        {currentPaymentPlan?.dataSize || "N/A"} 
                        {currentPaymentPlan?.dataSize !== "Unlimited"
                          ? ` / ${labelsIot.LBLI0165} ` 
                          : ` ${labelsIot?.LBLI0172 || "data"}`}
                      </p>
                    <p>{labelsIot?.LBLI0013} {language === "en" ? getPlanBundleDescription(currentPaymentPlan?.duration,currentPaymentPlan?.description,labelsIot):null}</p>
                    <div className="flex align-items-center justify-content-center gap-2 mt-5">
                      <PR.Image
                        src={Checkmark}
                        alt="Checkmark"
                        className="icon"
                      />
                      <text className="select-info">
                        {labelsIot?.LBLI0095}
                      </text>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 md:col-9 mobile-full-width">
                <div className="payment-methods">
                  <div className="payment-tabs">
                    <div className='payment-form'>
                      {isLoading && (
                        <div className="loading-section payment-load">
                          <i className="pi pi-spin pi-spinner mr-2"></i> {labelsIot?.LBLI0139}
                        </div>
                      )}
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                  <Footer />
        </section>
     );
}
 
export default React.memo(PaymentProvider);