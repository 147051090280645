import React, { useRef } from "react";
import * as PR from '../../prime-modules'
import AppleSignin from 'react-apple-signin-auth';
//import { authActions } from '../../store/auth';
import { envConfig } from "../../constants";
import { useMutation } from "react-query";
import { appleLoginAPI } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../store/auth.slice";
import { AppleIcon } from "../../assets/images";

import { useToast } from "../../context/ToastContext";

const AppleLogin = ({title, iccid = "", iccidCheck, screen,iccidPin=""}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {showToast} = useToast()
    const toast = useRef();

    const {mutate, isLoading} = useMutation(appleLoginAPI, {
        onSuccess: data => {navigate('/'); dispatch(login(data))} 
    })

    const appleResponse = res => {
        if (!res.error) {
            res.iccid = iccid;
            res.iccidPin = iccidPin
            mutate(res)
        } 
        else showToast("error", res.error ?? "Something went wrong!") //labels?.LBLN0283
    };

    const handleAppleLogin = (props) => {
      if ((iccidCheck && iccidCheck()) || screen ==="signin") {
        props.onClick();
      }
    };

    return (
        <>
            <AppleSignin
                authOptions={{
                    clientId: envConfig.REACT_APP_APPLE_CLIENT_ID,
                    redirectURI: envConfig.REACT_APP_APPLE_CALLBACK_URL,
                    scope: 'email name',
                    state: 'state',
                    nonce: 'nonce',
                    usePopup: true,
                }}
                onSuccess={appleResponse}
                render={props => {
                    return <PR.Button {...props} label={title} type="button" className={`social-button ${isLoading  ? "btn-disable " : ""}`}  icon={isLoading && "pi pi-spin pi-spinner"}
                      disabled={isLoading} onClick={() => handleAppleLogin(props)} >
                        <PR.Image src={AppleIcon} alt="Mail" className="apple" />
                      </PR.Button>
                }}
            />
            <PR.Toast ref={toast}/>
        </>
    );
}


 
export default AppleLogin;