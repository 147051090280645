import React from 'react';
import './i18n';
// **** Main CSS **** 
import './assets/scss/main.scss';
import Navigation from '../src/navigation';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import store from './store';
import { ToastProvider } from './context/ToastContext';
import { TranslationProvider } from './context/TranslationContext';
import useHotjar from './utils/useHotjar';
import { envConfig } from './constants';
import { plausible } from './utils/plausibleTracker';

function App() {
  const queryClient = new QueryClient();
  useHotjar(envConfig.REACT_APP_HOTJAR_ID);
  plausible.enableAutoPageviews();
  return (
    <Provider store={store} >
      <QueryClientProvider client={queryClient}>
        <TranslationProvider>
          <ToastProvider >
            <Navigation />
          </ToastProvider>
        </TranslationProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;