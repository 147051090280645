import React, { useCallback, useEffect, useState } from "react";
import html2pdf from 'html2pdf.js';
import * as PR from "../../prime-modules/index";
import { SuccessIcon, PendingIcon, Offline } from "../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalize, showDateWithTime, showDate, handleDownloadReceipt, setPrice } from "../../utils/reuse";
import { useTranslationContext } from "../../context/TranslationContext";
import PaymentProvider from "./PaymentProvider";
import { CURRENT_PAYMENT_PLAN, ICCID } from "../../utils/localstore";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { confirmSubscriptionPaymentAPI } from "../../services/api";
import { useToast } from "../../context/ToastContext";

const PaymentSuccess = () => {
  const {labelsIot} = useTranslationContext()
  const navigate = useNavigate();
  const { state, search, pathname } = useLocation();
  const userData = useSelector(state=>state.auth.userData)
  const [canShare, setCanShare] = useState(false);
  const { showToast } = useToast();

    const {mutate, isLoading } = useMutation(confirmSubscriptionPaymentAPI, {
      onSuccess: payRes => {
       
        navigate("/payment-success", {state: {payRes},replace:true});
      },
      onError: er => showToast("error", er?.message) 
    });

    const confirmPaymentHandler = useCallback(() => {
      mutate(search);
      navigate(pathname, {replace: true})
    }, [mutate, navigate, pathname, search]);

    useEffect(() => {
      if (!state?.payRes && search) {
        confirmPaymentHandler()
      }
    }, [confirmPaymentHandler, search, state]);

    let paymentSuccessStatus = state?.payRes?.transactionStatus === "COMPLETED"
    let paymentTitle = "";
    let paymentSubTitle = "";
    let paymentIcon;
    
    switch (state?.payRes?.transactionStatus) {
      case "COMPLETED":
        paymentTitle = labelsIot?.LBLI0096;
        paymentIcon = SuccessIcon;
        paymentSubTitle = labelsIot?.LBLI0098;
        break;
      case "PENDING":
        paymentTitle = labelsIot?.LBLI0154;
        paymentIcon = PendingIcon;
        paymentSubTitle = labelsIot?.LBLI0155;
        break;
        case "FAILED":
          paymentTitle = labelsIot?.LBLI0178;
          paymentIcon = Offline;
          paymentSubTitle = '';
          break;
      default:
        paymentTitle = labelsIot?.LBLI0154;
        paymentIcon = PendingIcon;
        paymentSubTitle = labelsIot?.LBLI0155;
        break;
    }

    useEffect(() => {
      // Check if the Web Share API is supported and can share files
      if (navigator.share && navigator.canShare) {
        setCanShare(true);
      }
    }, []);

    const handleConfirm = ()=>{
      localStorage.setItem(CURRENT_PAYMENT_PLAN,JSON.stringify({}));
       navigate("/dashboard",{state:{refresh:true},replace:true})
    }

  const downloadRecept = ()=>{
  const content = handleDownloadReceipt({...state?.payRes,...userData, iccid:localStorage.getItem(ICCID)})
    const options = {
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 3 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
   html2pdf().set(options).from(content).save(`${state?.payRes?.transactionId}.pdf`);
  }

    const shareRecept = ()=>{
      let doc =  handleDownloadReceipt({...state?.payRes,...userData,iccid:localStorage.getItem(ICCID)})
    const pdfBlob = doc.output('blob');
    // Call the share function
    sharePDF(pdfBlob);
    }

    const sharePDF = (pdfBlob) => {
      // Check if the browser supports the Web Share API
      if (navigator.share) {
        // Create a file from the Blob
        const file = new File([pdfBlob], 'invoice.pdf', {
          type: 'application/pdf',
        });
  
        // Use the Web Share API to share the PDF file
        navigator
          .share({
            title: 'Invoice PDF',
            text: 'Check out my invoice!',
            files: [file],
          })
      } else {
        alert('Web Share API not supported on this browser');
      }
    };
  return (
    <PaymentProvider
      data={{ price: state?.payRes?.totalOrderAmount }}
      isLoading={isLoading}
    >
      <section className="payment-success-section">
        <div className="grid">
          <div className="col-12 md:col-12">
            <div className="payment-details-block">
              <ul className="header-ul">
                <div className="flex align-items-center justify-content-between">
                  <li className="flex align-items-center gap-3">
                    <PR.Image
                      src={paymentIcon}
                      alt="Payment Success"
                      className="success"
                    />
                    <div className="card">
                      <h2>{paymentTitle}</h2>
                      {!!state?.payRes?.totalOrderAmount && (
                        <p>
                          {setPrice(state?.payRes?.totalOrderAmount)}
                        </p>
                      )}
                    </div>
                  </li>
                  {paymentSuccessStatus ? <li className="flex align-items-center gap-5">
                      {canShare ? <i onClick={()=>shareRecept()} className="pi pi-share-alt"></i> : null}
                   
                    
                      <span>
                        <i onClick={()=>downloadRecept()} className="pi pi-download"></i>
                      </span>
                   
                  </li>:null}
                </div>
               <h3>
                  {paymentSubTitle}
                  {paymentSuccessStatus ? <strong>
                    {" "}
                    {showDate(state?.payRes?.subscriptionNextDue)}.
                  </strong>:null}
                </h3> 
              </ul>
              <ul className="flex align-items-center justify-content-between">
                <li>{labelsIot?.LBLI0099}</li>
                <li>{state?.payRes?.transactionId}</li>
              </ul>
              <ul className="flex align-items-center justify-content-between">
                <li>{labelsIot?.LBLI0100}</li>
                <li>
                  <span className={`payment payment-${state?.payRes?.transactionStatus.toLowerCase()}`}>{capitalize(state?.payRes?.transactionStatus)}</span>
                </li>
              </ul>
              <ul className="flex align-items-center justify-content-between">
                <li>{labelsIot?.LBLI0101}</li>
                <li>{state?.payRes?.paymentGateway}</li>
              </ul>
              <ul className="flex align-items-center justify-content-between">
                <li>{labelsIot?.LBLI0102}</li>
                <li>{showDateWithTime(state?.payRes?.completedTs)}</li>
              </ul>
              <div className="card flex justify-content-end">
                <PR.Button
                  label={labelsIot?.LBLI0103}
                  className="confirm-button mt-5 custom-width"
                  onClick={handleConfirm}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </PaymentProvider>
  );
};

export default PaymentSuccess;
