import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./index.scss";
import * as PR from "../../prime-modules/index";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutHandler } from "./helper";
import { useMutation } from "react-query";
import { profileValidationSchema } from "../../utils/schema";
import { logout, updateUserData } from "../../store/auth.slice";
import { deleteAccountAPI, logoutAPI, updateProfileAPI } from "../../services/api";
import ChangePassword from "./ChangePassword";
import { useTranslationContext } from "../../context/TranslationContext";
import { useToast } from "../../context/ToastContext";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hooks/useLanguage";
import { languages } from "../../utils/localstore";

const Settings = ({setSelectedSection}) => {
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.auth);
  const languageAssert = useSelector(state => state.language)
  const { labelsIot, validationsIot: validations, messageCatalog } = useTranslationContext();
  const { i18n } = useTranslation();
  const [editStatus, setEditStatus] = useState(false)
  const { showToast, showMessage } = useToast()
  const dispatch = useDispatch();
  const language = i18n.language.split('-')[0];

  const { changeLanguage } = useLanguage(languageAssert, language)

  const [deleteAccount, setDeleteAccount] = useState({});
  const [logoutAccount, setLogoutAccount] = useState({});
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (success) {
      const clearSuccess = setTimeout(() => {
        setSuccess("")
      }, 3000)
      return () => clearSuccess
    }
  }, [success])



  const { mutate } = useMutation(updateProfileAPI, {
    onSuccess: () => {
      formik.setSubmitting(false);
      setSuccess(messageCatalog["USRPRF-04"])
      if (formik.values.email !== userData?.email) {
        setTimeout(() => {
          dispatch(logout());
        }, 2000)
        return
      }
      dispatch(updateUserData({ ...userData, ...formik.values }));
    },
    onError: o => {
      formik.setSubmitting(false);
      showToast("error", o?.message)
    },
  });

  const { mutate: deleteAccountHandler } = useMutation(deleteAccountAPI, {
    onSuccess: () => {
      setDeleteAccount({ ...deleteAccount, message: labelsIot?.LBLI0079 })
      showToast("success", labelsIot?.LBLI0079)
      setTimeout(() => {
        setDeleteAccount({});
        dispatch(logout());
      }, [2000])
    },
    onError: o => {
      setDeleteAccount({ show: true });
      // showToast("error", o?.message)
    },
  });

  const initialValues = {
    firstName: userData?.firstName + " " + userData?.lastName || "",
    email: userData?.email ? userData.email : "",
  }
  const handleSubmit = (values) => {
    const request = { ...values, userId: userData.userId }
    setEditStatus(false)
    mutate(request);
  };
  const formik = useFormik({
    initialValues,
    validationSchema: profileValidationSchema,
    onSubmit: handleSubmit
  })

  const headerElement = (
    <div className="flex align-items-center gap-3">
      <h2><span><i className="pi pi-trash"></i></span>{labelsIot?.LBLI0093}</h2>
    </div>
  );

  const headerLogoutElement = (
    <div className="flex align-items-center gap-3">
      <h2><span><i className="pi pi-sign-out"></i></span>{labelsIot?.LBLI0089}</h2>
    </div>
  );

  const changePasswordHeaderElement = (
    <div className="flex align-items-center gap-3">
      <h2><span><i className="pi pi-lock"></i></span> {labelsIot?.LBLI0076}</h2>
    </div>
  );


  return (
    <>
      <PR.Dialog
        visible={!!deleteAccount?.show}
        onHide={() => setDeleteAccount({ show: false })}
        draggable={false}
        header={headerElement}
        baseZIndex={1}
        maskClassName="add-device-dialog"
      >
        <div className="cancel-subscription">
          <h3>{labelsIot?.LBLI0077}</h3>
          <p className="small">
            {labelsIot?.LBLI0040}: {labelsIot?.LBLI0078}
          </p>
          <div className="col-12 p-0">
            <PR.Button
              label={labelsIot?.LBLI0085}
              type="submit"
              className="device-confirm-button"
              disabled={deleteAccount?.disable}
              onClick={() => {
                setDeleteAccount({ ...deleteAccount, disable: true });
                deleteAccountHandler();
              }}
            />
          </div>
        </div>
      </PR.Dialog>

      <PR.Dialog
        visible={!!logoutAccount?.show}
        onHide={() => setLogoutAccount({ show: false })}
        draggable={false}
        header={headerLogoutElement}
        baseZIndex={1}
        maskClassName="add-device-dialog"
      >

        <div className="cancel-subscription">
          <h3>{labelsIot?.LBLI0094}</h3>
          <div className="button-container col-12 p-0 mt-3">
            <PR.Button
              label={labelsIot?.LBLI0123}
              className="device-confirm-button cancel-button"
              onClick={() => {
                setLogoutAccount({ show: false });
              }}
            />
            <PR.Button
              label={labelsIot?.LBLI0089}
              type="submit"
              className="device-confirm-button"
              disabled={logoutAccount?.disable}
              onClick={() => {
                setLogoutAccount({ ...logoutAccount, disable: true });
                logoutAPI().then(result=>{
                  logoutHandler(dispatch);
                }).catch(e=>{
                  showMessage(e?.message)
                })
                navigate("/")
              }}
            />
          </div>
        </div>
        {/* {deleteAccount?.message && (
          <p className="delete-success">Account deleted successfully!</p>
        )} */}
      </PR.Dialog>

      {/* Change Password Dialog */}
      <PR.Dialog
        visible={showChangePassword}
        onHide={() => setShowChangePassword(false)}
        draggable={false}
        header={changePasswordHeaderElement}
        baseZIndex={1}
        maskClassName="add-device-dialog"
      >
        <ChangePassword setShowChangePassword={setShowChangePassword} />
      </PR.Dialog>

      <div className="add-device">
        <div className="content-header flex align-items-center justify-content-between">
          <h1>{labelsIot?.LBLI0080}</h1>
        </div>
        <div className="settings-section">
          <div className="grid grid-nogutter w-12">
            <div className="col-12 md:col-6 left-block">
              <div className="flex align-items-center justify-content-between">
                <h2>{labelsIot?.LBLI0081}</h2>
                <PR.Button
                  label={labelsIot?.LBLI0090}
                  onClick={() => setEditStatus(state => !state)}
                  icon="pi pi-pencil"
                  className={`edit-button ${editStatus ? 'active-class' : ''}`}
                />
              </div>
              <form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                className="mt-5"
              >
                <div className="grid mb-3">
                  <div className="col-12">
                    <div className="flex flex-column gap-2">
                      <label htmlFor="firstname">{labelsIot?.LBLI0122}</label>
                      <PR.InputText
                        name="firstName"
                        value={formik.values.firstName}
                        disabled={!editStatus}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <div className="err-msg">{validations[formik.errors.firstName]}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card flex flex-column relative gap-2 mb-4">
                  <label htmlFor="email">{labelsIot?.LBLI0082}</label>
                  <PR.InputText
                    name="email"
                    value={formik.values.email}
                    disabled={!editStatus || userData?.signupType !== ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="John.smith@example.com"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="err-msg">{validations[formik.errors.email]}</div>
                  )}
                </div>
                  {userData?.signupType === "" ?  <div className="card flex flex-column relative gap-2 mb-4">
                  <label htmlFor="password">{labelsIot?.LBLI0083}</label>
                  <PR.Password
                    value={"***************"}
                    feedback={false}
                    disabled={true}
                    tabIndex={1}
                  />
                  <PR.Button
                    label={labelsIot?.LBLI0076}
                    className="password-button"
                    type="button"                 
                    onClick={() => setShowChangePassword(true)}
                  />
                </div>:null}
                <div className="flex align-items-center gap-4 mt-2">
                  <PR.Button
                    label={labelsIot?.LBLI0084}
                    className="save-button"
                    type="submit"
                    disabled={formik.isSubmitting || !editStatus}
                  />
                  {success && <span className="success-msg">{success}</span>}
                </div>
              </form>
              {/* <div className="card">
                <PR.Button
                  label={labelsIot?.LBLI0085}
                  icon="pi pi-trash"
                  className="trash-button"
                  type="button"
                  onClick={() => setDeleteAccount({ show: true })}
                />
              </div> */}
            </div>
            <div className="col-12 md:col-6 right-block">
              <h2>{labelsIot?.LBLI0086}</h2>
              <PR.Dropdown
                value={languages?.filter(e => e.code.toLowerCase() === language)?.[0]}
                onChange={changeLanguage}
                options={languages}
                optionLabel="name"
                className="language-dropdown"
                placeholder="English"
              />
              <div className="flex flex-column gap-5 useful-links">
                <Link onClick={() => setSelectedSection('termsandconditions')}>{labelsIot?.LBLI0087}</Link>
                <Link onClick={() => setSelectedSection('privacypolicy')}>{labelsIot?.LBLI0120}</Link>
                <Link onClick={() => setSelectedSection('cookiepolicy')}>{labelsIot?.LBLI0188 || "Cookie Policy"}</Link>
                <Link onClick={() => setLogoutAccount({ show: true })}>{labelsIot?.LBLI0089}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
