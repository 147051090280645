import React, { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const {labelsIot, messageCatalog, validationsIot, faq} = useSelector(state => state.language?.activelang);

  return (
    <TranslationContext.Provider value={{labelsIot, messageCatalog, validationsIot, faq}}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationContext= () => {
  return useContext(TranslationContext);
};