import React from "react";
import "./index.scss";
import { useTranslationContext } from "../../context/TranslationContext";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { getlanguageAPI } from "../../services/api";
import { setLanguageIntial } from "../../store/language.slice";

const TermsAndConditions = ({ setSelectedSection }) => {
  const dispatch = useDispatch();
  const lnFromStore = useSelector((state) => state.language);
  const language = "en";
  let { isLoading } = useQuery(
    ["policyTerms", language],
    getlanguageAPI.bind(null, language, "policyTerms"),
    {
      enabled: !lnFromStore[language],
      onSuccess: (o) => dispatch(setLanguageIntial({ k: language, v: o })),
    }
  );
  const { labelsIot } = useTranslationContext();
  const policyTerms = useSelector(
    (state) => state.language?.activelang?.policyTerms
  );
  const cookiePolicy = !!policyTerms?.cookiePolicy
    ? JSON.parse(policyTerms.cookiePolicy)
    : {};

  return (
    <>
      <div className="add-device">
        {setSelectedSection && <p
          className="backto-button"
          onClick={() => setSelectedSection("settings")}
        >
          <i className="pi pi-arrow-left pr-2"></i> {labelsIot?.LBLI0140}
        </p>}
        {isLoading ? (
          <div className="full-window-loading payment-loading">
            <i className="pi pi-spin pi-spinner"></i>
            <h2 color="white" style={{ color: "white" }}>
              {labelsIot?.LBLI0139}
            </h2>
          </div>
        ) : (
          <>
            <div className="content-header flex align-items-center justify-content-between">
              <h1>{cookiePolicy.title}</h1>
            </div>
            <div className="content-section">
              <div className="grid grid-nogutter w-12">
                <div className="col-12 md:col-12">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: cookiePolicy.description,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TermsAndConditions;
