import React from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import CookiePolicy from "../dashboard/CookiePolicy";
import "./index.scss";

const CookiePolicyPage = () => {
  return (
    <>
        <Header/>
            <section className="terms-and-conditions-section">
            <CookiePolicy />
            </section>
        <div className="footer-block">
            <Footer/>
        </div>
    </>
  );
};

export default CookiePolicyPage;