import { useEffect } from "react";

const useHotjar = (HOTJAR_ID, HOTJAR_VERSION = 6) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      (function(h, o, t, j, a, r) {
        h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments); };
        h._hjSettings = { hjid: HOTJAR_ID, hjsv: HOTJAR_VERSION };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script"); r.async = 1;
        r.src = `https://static.hotjar.com/c/hotjar-${HOTJAR_ID}.js?sv=${HOTJAR_VERSION}`;
        a.appendChild(r);
      })(window, document);
    }
  }, [HOTJAR_ID, HOTJAR_VERSION]);
};

export default useHotjar;
