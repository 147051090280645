import { useField } from "formik";
import * as PR from "../../prime-modules/index";
import './Form.scss'
import { useTranslationContext } from "../../context/TranslationContext";
import { useLocation } from "react-router-dom";

const Input = (props) => {
    const {pathname} = useLocation()
    const [field, meta] = useField(props);
    const { validationsIot: validations } = useTranslationContext()
    const autoFillState = pathname === "/signup" ? false : true;
    return <div className="flex flex-column gap-2">
        <label htmlFor={props?.name}> {props?.label}</label>
        {
            props?.type === 'password'
                ? <PR.Password  {...field} {...props} autoComplete="current-password" />
                : props?.type === "message" ? <PR.InputTextarea  {...field} {...props} /> 
                : props?.type === 'option' ? <PR.Dropdown {...field} {...props} /> : <PR.InputText  {...field} {...props} autoComplete={props?.type === "email" ? "email" : ""} autoFocus={autoFillState} />
        }
       {meta.touched && meta.error && <span className="err-msg">{validations[typeof meta.error === "object"? Object.values(meta.error)[0]:  meta.error] ?? 'Error'}</span>}
    </div>
}

export default Input