import AppleLogin from "./AppleLogin";
import GoogleLogin from "./GoogleLogin";
import { useTranslationContext } from "../../context/TranslationContext";

const GoogleAndAppleLoginButtons = ({iccid, iccidCheck, screen, iccidPin}) => {
    const {labelsIot} = useTranslationContext()

    return ( 
        <div className="card flex flex-column">
            <span className="or-span">or</span>
            <GoogleLogin title={labelsIot.LBLI0007} iccidCheck={iccidCheck} screen={screen} />
            <AppleLogin title={labelsIot.LBLI0008} iccidPin={iccidPin} iccid={iccid} iccidCheck={iccidCheck} screen={screen} />
        </div>
    );
}
 
export default GoogleAndAppleLoginButtons;