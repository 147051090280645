import axios from "axios";
import store from "../store";
import { logout } from "../store/auth.slice";

export const globalConfig = {
    domainName: 'data2go',
    dataSize: 'GB',
    defaultCurrency: '€',
    libraries: ['places'],
    allowPhoneNumberLength: 16,
    paymentDefaultCurrency: 'eur',
    // paymentDefaultCountry: 'US',
    // paymentDefaultLabel: 'eSIM Purchase',
    pageCount: 20,
    domainUrl: "https://data2go.mobi",
    thingsDomainUrl: "https://things.data2go.mobi",
    supportEmail: 'support@data2go.mobi',
    contactEmail: 'contact@data2go.mobi',
    salesEmail: 'sales@data2go.mobi',
    phoneNumber: "+44 1223 926858",
    allowNameLength: 20,
    toastDisplayTime: 5000,
    errorToastDisplayTime: 10000000,
    deviceInfo: "website",
    isEsimCompatible: false,
    catalogDataAmountperGB: 1000,
    notificationCount: 20
}

export const client = axios.create({
    baseURL: '/v1/',
});

export const clientV2 = axios.create({
    baseURL: '/v2/',
});

// Setting the session ID for each api request if session ID available in redux Store
client.interceptors.request.use(
    config => {
        const state = store.getState();
        const sessionId = state?.auth?.userData?.sessionId;
        if (!!sessionId) config.headers['Sessionid'] = sessionId;
        return config;
    },
    error => error? Promise.reject(error) : "GNRMSG-07"
);
clientV2.interceptors.request.use(
    config => {
        const state = store.getState();
        const sessionId = state?.auth?.userData?.sessionId;
        if (!!sessionId) config.headers['Sessionid'] = sessionId;
        return config;
    },
    error => error? Promise.reject(error) : "GNRMSG-07"
);


// Logging out the user if the response is unauthenticated on each api response 
client.interceptors.response.use(
    res => res,
    error => {
        if (error.response && error.response.status === 401) {
            store.dispatch(logout());
        }
        return error ? Promise.reject(error) : "GNRMSG-07"
    }
)
clientV2.interceptors.response.use(
    res => res,
    error => {
        if (error.response && error.response.status === 401) {
            store.dispatch(logout());
        }
        return error ? Promise.reject(error) : "GNRMSG-07"
    }
)

const apiWithSessionId = data => client(data)
    .then(res => ({ ...res.data, sessionId: res.headers.sessionid }))
    .catch((err) => { throw new Error(err?.response?.data?.errorMsg ?? "GNRMSG-07"); });

export const api = data => client(data)
    .then((res) => res.data)
    .catch((err) => { throw new Error(err?.response?.data?.errorMsg ?? "GNRMSG-07"); });
export const apiV2 = data => clientV2(data)
    .then((res) => res.data)
    .catch((err) => { throw new Error(err?.response?.data?.errorMsg ?? "GNRMSG-07"); });


export const getBundleslist = url => api({
    method: 'get',
    url: `catalogs?${url}`
});

export const logoutAPI = () => api({
    method: 'get',
    url: `logout`
});

export const getDevicelist = (keyword) => api({
    method: 'get',
    url: `esims${!!keyword ? `?search=${keyword}`:""}`
});

export const getPaymentList = (keyword) => api({
    method: 'get',
    url: `payments${!!keyword ? `?search=${keyword}`:""}`
});

export const getCountries = () => client({ method: 'get', url: `countries` })
    .then((res) => ({ data: res.data, ipcountry: res.headers['x-cf-ipcountry'] }))
    .catch((err) => { throw new Error(err?.response?.data?.errorMsg ?? "GNRMSG-07"); });

export const handleSignup = data => api({
    method: 'post',
    url: "signup",
    data
});

export const forgotPassword = (email) => api({
    method: 'get',
    url: `forgotpassword?email=${email}`
})

export const subscriptionPlans = ({queryKey}) => {
    const [, params] = queryKey; // Extract params from queryKey
    return client({
      method: 'get',
      url: `subscription-plans?dataamountfordisplay=${params}`, // Use dynamic params
    })
      .then((res) => {
        // Save country code to localStorage
        localStorage.setItem('iot-country-code', res.headers['x-cf-ipcountry']);
        return res.data;
      })
      .catch((err) => {throw new Error(err?.response?.data?.errorMsg ?? "GNRMSG-07"); });
  }


export const handleSignin = data => apiWithSessionId({ method: 'post', url: 'login', data })
export const handleResetPasswordAPI = data => apiWithSessionId({ method: 'put', url: 'resetpassword', data })

export const googleLoginAPI = code => apiWithSessionId({ method: 'get', url: 'googlelogin' + code })

export const appleLoginAPI = data => apiWithSessionId({ method: 'post', url: 'appleloginweb', data })

//affiliate contact
export const affiliateSignup = data => api({ method: 'post', url: 'contact', data });

export const verifyEmail = (url, data) => api({
    method: 'post',
    url,
    data
});

export const resendOTP = data => api({
    method: 'post',
    url: 'reverifyemail',
    data
});

export const googleLogin = (code) => api({
    method: 'get',
    url: `googlelogin?code=${code}`
});

export const updateProfileAPI = (data) => api({
    method: 'put',
    url: `profile`,
    data
});

export const paymentMethodAPI = data => api({
    method: 'post',
    url: data.paymentGateway === "Paypal" ? "paypalpayment" : `stripepayment`,
    data
});

export const getlanguageAPI = (ln,screen) => api({ method: 'get', url: `assets?language=${ln}&screen=${screen}` })

export const getPlansDropDownAPI = () => api({ method: 'get', url: `subscription-plans/filters` })


export const newDeviceApi = data => api({
    method: 'post',
    url: "sims",
    data
});


export const cancelDeviceApi = data => api({
    method: 'post',
    url: `cancelsub`,
    data
});

export const removeDeviceApi = id => api({
    method: 'delete',
    url: `esims/${id}`,
});

export const deleteAccountAPI = () => api({
    method: "get",
    url: "deleteuser"
});

export const changeUserPasswordAPI = (data) => api({
    method: 'put',
    url: `changepassword`,
    data,
})

const subscriptionAPI = ({method, url, data}) => client({method, url, data: data?.request})
    .then((res) => ({...res?.data, ...data?.plan, randomId: data?.request?.randomId}))
    .catch((err) => { throw new Error(err?.response?.data?.errorMsg ?? "GNRMSG-07"); });

export const createSubscriptionSessionAPI = (data) => subscriptionAPI({
    method: 'post',
    url: `stripe/subscriptions`,
    data,
});

export const confirmSubscriptionPaymentAPI = (data) => api({
    method: 'get',
    url: `stripe/subscriptions/status` + data,
});

export const changeSubscriptionPaymentMethodAPI = (data) => api({
    method: 'put',
    url: `/esims/${data.iccid}/subscription`,
    data: data.request,
});

export const editTag = (data) => api({
    method: 'put',
    url: `edittag`,
    data,
})

export const esimsV2API = () => apiV2({
    method: 'get',
    url: `esims`,
})


export const fetchEsimDetails = (iccid) => api({
    method: 'get',
    url: `esims/${iccid}` 
});
