import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import i18n from 'i18next';
import { getlanguageAPI } from '../services/api';
import { setLanguageIntial, setLanguageStore } from '../store/language.slice';

// Reusable language handling hook
export const useLanguage = (languageAssert, language) => {
  const dispatch = useDispatch();

  // Callback to change language
  const changeLanguage = useCallback((lang) => {
    const selectedLanguage = lang.value.code.toLowerCase();
    i18n.changeLanguage(selectedLanguage);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (languageAssert[language]) {
    dispatch(setLanguageStore(languageAssert[language]));
    }
  }, [language, dispatch]);

  useQuery(['home',language],getlanguageAPI.bind(null,language,"labelsIot"),{
    enabled:!languageAssert[language],
    retry:1,
    onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
  });
  useQuery(['error',language],getlanguageAPI.bind(null,language,"validationsIot"),{
    enabled:!languageAssert[language],
    retry:1,
    onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
  });
  useQuery(['message',language],getlanguageAPI.bind(null,language,"messageCatalog"),{
    enabled:!languageAssert[language],
    retry:1,
    onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
  });

  useQuery(['faq',language],getlanguageAPI.bind(null,language,"faq-new"),{
    enabled:!languageAssert[language],
    retry:1,
    onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
  });

  useQuery(['policyTerms',language],getlanguageAPI.bind(null,language,"policyTerms"),{
    enabled:!languageAssert[language],
    retry:1,
    onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
  })

  return {
    changeLanguage,
  };
};
